import moment from 'moment';

export function parseJwt(token=localStorage.getItem('token')) {
    if (!token) { return null; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export function getToken() {
    return localStorage.getItem("token");
}

export function logoutOnTokenExpire() {
    const token = parseJwt();
    if (!token){
        return null;
    } else if (token.exp) {
        const expirationTime = moment(new Date(token.exp*1000));
        if(expirationTime.isBefore(moment.now())){
            logOut();
        }
    }
}

export function logOut() {
    localStorage.clear();
    window.location.href = '/signin';

}