import React, { useEffect } from "react"
import { logOut } from "../../util/jwt.helper"

export function RefreshPage() {

    useEffect(() => {
        logOut()
    }, [])

    return (
        <div>
            <p>Redirigiendo...</p>
        </div>
    )
}